/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import {
  DownOutlined,
  RightOutlined,
  QuestionCircleOutlined,
  SmileOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Input,
  Collapse,
  Form,
  InputNumber,
  DatePicker,
  Space,
  Option,
  Popconfirm,
  Select,
  Spin,
  Table,
  notification,
  Divider,
  Alert,
} from "antd";
import { useNavigate, useParams, useLocation, useNavigation } from "react-router-dom";
import {
  SEMESTERS_LIST,
  SEMESTER_REGISTRATION_ADD,
  SEMESTER_REGISTRATION_STUDENT_VIEW,
  SEMESTER_REGISTRATION_UPDATE,
  SIS_STORAGE,
  STUDENTS_LIST,
} from "../../apis/apis";
import SisLoader from "../../widgets/loader";
import SemesterSectionBox from "./SemesterSectionBox";
import "./style.css";
import TransferredCourse from "./transferred-course/TransferredCourse";
const SemesterRegistrationStudent = () => {
  const { TextArea } = Input;
  const navigate = useNavigate();
  const { id, sem_id, student_id } = useParams();
  const [loader, set_loader] = useState(true);
  const [errors, set_errors] = useState([]);
  const [course_ids, set_course_ids] = useState([]);
  const [user_id, set_user_id] = useState("");
  const [api_data, set_api_data] = useState("");
  const [sem_reg_id, set_sem_reg_id] = useState("");
  const [student_status, set_student_status] = useState("");
  const [admission_status, set_admission_status] = useState("");
  const [registration_no, set_registration_no] = useState("");
  const [registration_status, set_registration_status] = useState("");
  const [financial_aid, set_financial_aid] = useState("");
  const [sct_status, set_sct_status] = useState("");
  const [page_loader, set_page_loader] = useState(true);
  const [semester_registration, set_semester_registration] = useState("");
  const [basic_information, set_basic_information] = useState("");
  const [semester, set_semester] = useState("");
  const [program, set_program] = useState("");
  const [sections, set_sections] = useState("");
  const [mailing_address, set_mailing_address] = useState("");
  const [sections_with_subsections, set_sections_with_subsections] =
    useState("");
  const [transferred_Course, set_transferred_Course] = useState(false);
  const [transferred_course_list, set_transferred_course_list] = useState([]);
  const [main_page_loader, set_main_page_loader] = useState(true)
  const [student_type, set_student_type] = useState('')
  const [student_status_check, set_student_status_check] = useState();
  const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
  const FORM_DATA = new FormData();
  FORM_DATA.append("token", USER_DATA.token);
  FORM_DATA.append("acad_calender_id", atob(id));
  FORM_DATA.append("semester_id", atob(sem_id));
  FORM_DATA.append("student_id", atob(student_id));

  const SEMESTER_REGISTRATION_STUDENT_VIEW_API = async () => {
  
    const API_RESPONSE = await SEMESTER_REGISTRATION_STUDENT_VIEW(FORM_DATA);
    if (API_RESPONSE?.data?.status) {

      set_semester_registration(API_RESPONSE?.data?.semester_registration);

      if (API_RESPONSE?.data?.semester_registration) {
        set_api_data(API_RESPONSE?.data);
        set_sem_reg_id(API_RESPONSE?.data?.semester_registration.id);
        set_student_status(
          API_RESPONSE?.data?.semester_registration.student_status
        );
        set_admission_status(
          API_RESPONSE?.data?.semester_registration.admission_status
        );
        set_registration_no(
          API_RESPONSE?.data?.semester_registration.registration_no
        );
      
        set_financial_aid(
          API_RESPONSE?.data?.semester_registration.financial_aid
        );
        set_sct_status(API_RESPONSE?.data?.semester_registration.sct_status);
        set_course_ids(API_RESPONSE?.data?.semester_registration.course_ids);
        set_transferred_course_list(
          API_RESPONSE?.data?.transferred_course_list
        );
        if (API_RESPONSE?.data?.transferred_course_list?.length > 0) {
          set_transferred_Course(true);
        }
        set_registration_status(API_RESPONSE?.data?.semester_registration?.registration_status)
      }

      set_basic_information(API_RESPONSE?.data?.basic_information); 
      set_semester(API_RESPONSE?.data?.semester);
      set_student_type(API_RESPONSE?.data?.students_programmes_registrations?.student_type)  
      set_mailing_address(
        JSON.parse(API_RESPONSE?.data?.basic_information?.mailing_address)
      );
      set_program(API_RESPONSE?.data?.program);
      set_sections(API_RESPONSE?.data?.sections);
      set_loader(false);
      set_page_loader(false);
      set_main_page_loader(false)
    } else {
      set_loader(false);
    }
  };


  useEffect(() => {

    SEMESTER_REGISTRATION_STUDENT_VIEW_API();

  }, [main_page_loader, loader]);

  const ADD_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("acad_calender_id", atob(id));
    FORM_DATA.append("semester_id", atob(sem_id));
    FORM_DATA.append("student_id", atob(student_id));
    FORM_DATA.append("user_id", USER_DATA.user_id);
    FORM_DATA.append("student_status", registration_status);
    FORM_DATA.append("course_ids", JSON.stringify(course_ids));
    FORM_DATA.append("admission_status", admission_status);
    FORM_DATA.append("registration_no", registration_no);
    FORM_DATA.append("registration_status", registration_status);
    FORM_DATA.append("financial_aid", basic_information?.financial_aid);
    FORM_DATA.append("sct_status", sct_status);
    const API_RESPONSE = await SEMESTER_REGISTRATION_ADD(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Student Successfully registered in the semester.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_loader(false);
      navigate('/semester-registration-view/'+id+"/"+sem_id)
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };
  const UPDATE_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", sem_reg_id);
    FORM_DATA.append("acad_calender_id", atob(id));
    FORM_DATA.append("semester_id", atob(sem_id));
    FORM_DATA.append("student_id", atob(student_id));
    FORM_DATA.append("user_id", USER_DATA.user_id);
    FORM_DATA.append("student_status", registration_status);
    FORM_DATA.append("course_ids", JSON.stringify(course_ids));
    FORM_DATA.append("admission_status", admission_status);
    FORM_DATA.append("registration_no", registration_no);
    FORM_DATA.append("registration_status", registration_status);
    FORM_DATA.append("financial_aid", basic_information?.financial_aid);
    FORM_DATA.append("sct_status", sct_status);
    const API_RESPONSE = await SEMESTER_REGISTRATION_UPDATE(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description:
          "Student information for the semester updated Successfully.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_loader(false);
      navigate('/semester-registration-view/'+id+"/"+sem_id)
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };
  return (
    <>
      {page_loader ? (
        <SisLoader />
      ) : (
        <>
          <div className="registration-view">
            <div className="theme-content-head">
              <div className="theme-content-left-head">
                <h3>Semester Registration</h3>
              </div>
            </div>
            <div className="registration-content">
              <h4>Basic Information</h4>
            </div>
            <div className="basic-info-flex">
              <div className="input-box">
                <br></br>
                <img
                  src={
                    basic_information.image
                      ? SIS_STORAGE + "/enrollments/" + basic_information.image
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjxq6NJzoC0tZU1-AlOwCjVYTNe1Zj6NHkUJwAcJzv_nQTWGi3KBCj1OQsx2F1vVVDszo&usqp=CAU"
                  }
                  frameborder="0"
                  style={{ width: "180px", height: "180px" }}
                ></img>
              </div>
              <div className="input-box">
                <label>First name</label>
                <Input
                  style={{ width: "100%" }}
                  value={basic_information?.first_name}
                  disabled
                />
              </div>
              <div className="input-box">
                <label>Middle name</label>
                <Input
                  style={{ width: "100%" }}
                  value={
                    basic_information?.middle_name === "null"
                      ? ""
                      : basic_information.middle_name
                  }
                  disabled
                />
              </div>
              <div className="input-box">
                <label>Last name</label>
                <Input
                  style={{ width: "100%" }}
                  value={basic_information?.last_name}
                  disabled
                />
              </div>
              <div className="input-box">
                <label>Student ID</label>
                <Input
                  style={{ width: "100%" }}
                  value={basic_information?.student_id}
                  disabled
                />
              </div>
            </div>
            <Divider />
            <h3 style={{ margin: "25px 0px 15px" }}>Contact Details</h3>
            <div className="row">
              <div className="col-6">
                <div className="input-box">
                  <label>Primary Email</label>
                  <Input value={basic_information?.primary_email} disabled />
                </div>
              </div>
              <div className="col-6">
                <div className="input-box">
                  <label>Phone number</label>
                  <Input value={basic_information?.phone} disabled />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="input-box">
                  <label>Country</label>
                  <Input
                    value={mailing_address.mailing_address_country}
                    disabled
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="input-box">
                  <label>City</label>
                  <Input
                    value={mailing_address.mailing_address_city}
                    disabled
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="input-box">
                  <label>Postal code</label>
                  <Input
                    value={mailing_address.mailing_address_postal}
                    disabled
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="input-box">
                  <label>Address Field 1</label>
                  <Input
                    value={mailing_address?.mailing_address1}
                    disabled
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="input-box">
                  <label>Address Field 2</label>
                  <Input
                    value={mailing_address?.mailing_address2 === "null" ?  '' : mailing_address.mailing_address2 }
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="input-box">
                  <label>Programme/Degree Enrolled</label>
                  <Input value={program?.title} disabled />
                </div>
              </div>
              <div className="col-4">
                <div className="input-box">
                  <label>Student Type</label>
                  <Input value={student_type} disabled />
                </div>
              </div>
            </div>

            <div
              className={
                transferred_Course
                  ? "transferred-course active"
                  : "transferred-course"
              }
            >
              <label style={{ marginRight:"10px" }}>
                <Checkbox
                  checked={transferred_Course ? true : false}
                  onChange={(e) => {
                    e.target.checked
                      ? set_transferred_Course(true)
                      : set_transferred_Course(false);
                  }}
                />{" "}
                Add Transferred Courses
              </label>
              {transferred_Course && (
                <TransferredCourse
                  acad_calender_id={atob(id)}
                  semester_id={atob(sem_id)}
                  student_id={atob(student_id)}
                  set_transferred_Course={set_transferred_Course}
                  set_sem_reg_id={set_sem_reg_id}
                  sem_reg_id={sem_reg_id}
                  set_main_page_loader={set_main_page_loader}
                />
              )}
            </div>

            <Divider />
            <h3 style={{ margin: "25px 0px 15px" }}>Registration Details</h3>
            <div className="row">
              <div className="col-3">
                <div className="input-box">
                  <label>Semester</label>
                  <Input value={semester.title} disabled />
                </div>
              </div>
              <div className="col-3">
                <div className="input-box">
                  <label>Admission Status</label>
                  <Select
                    value={admission_status === 'null' ? '' : admission_status}
                    onChange={(value) => set_admission_status(value)}
                    style={{ width: "100%" }}
                    placeholder="--Admission Status--"
                  >
                    <Select.Option value="Full Time">Full Time</Select.Option>
                    <Select.Option value="Part Time">Part Time</Select.Option>
                  </Select>
                  {errors?.admission_status && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.admission_status[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="col-3">
                <div className="input-box">
                  <label>SCT Status</label>
                  <Select
                    value={sct_status}
                    onChange={(value) => set_sct_status(value)}
                    style={{ width: "100%" }}
                    placeholder="--SCT Status--"
                  >
                    <Select.Option value="Domestic">Domestic</Select.Option>
                    <Select.Option value="International">
                      International
                    </Select.Option>
                  </Select>
                  {errors?.sct_status && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.sct_status[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="col-3">
                <div className="input-box">
                  <label>Registration Number</label>
                  <Input
                    value={registration_no === 'null' ? '' : registration_no}
                    onChange={(e) => set_registration_no(e.target.value)}
                  />
                  {errors?.registration_no && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.registration_no[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="input-box">
                  <label>Financial Aid</label>
                  <Input value={basic_information?.financial_aid} disabled />
                  {errors?.financial_aid && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.financial_aid[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <Divider />
            <h3 style={{ margin: "25px 0px 15px" }}>Student Status</h3>

            <div className="row">
              <div className="col-3">
                <div className="input-box">
                  <label>Student Status</label>
                  <Select
                    value={registration_status && registration_status != 'null' ? parseInt(registration_status) : ''}
                    onChange={(value) => set_registration_status(value)}
                    style={{ width: "100%" }}
                    placeholder="--Student Status--"
                    disabled={parseInt(registration_status) > 1 ? true : false} 
                  >
                  
                  <Select.Option value={1}>
                    Ready for payment
                    </Select.Option>
                 {parseInt(registration_status) > 1 && <>
                
                    <Select.Option value={2}>
                    Paid
                    </Select.Option>
                    <Select.Option value={3}>
                    Partial Paid
                    </Select.Option>
                    <Select.Option value={4}>
                    Not Paid
                    </Select.Option>
                    
                 </>}


                  </Select>
                  
                  {errors?.registration_status && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.registration_status[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <Alert
              message="Registering courses"
              description={
                <>
                  <ol style={{ position: "relative", left: "-26px" }}>
                    <li>
                      {" "}
                      For this specific student, registrar needs to register
                      courses. All these sections and sub-sections are coming as
                      per the programme student is enrolled in.{" "}
                    </li>
                    <li>
                      {" "}
                      The course classes, timings, room and faculty is coming
                      from the scheduling module.{" "}
                    </li>
                    <li>
                      {" "}
                      Registrar can decide how many units to be allotted for
                      each course as a dropdown, maximum units will be the total
                      units set to the course.{" "}
                    </li>
                  </ol>
                </>
              }
              type="info"
              showIcon
            />
            <Divider />
            <h3 style={{ margin: "25px 0px 15px" }}>Courses Registration</h3>
            <p style={{ margin: "20px 0px" }}>
              Register courses for this semester.
            </p>
            <h4 style={{ marginBottom: "20px" }}>
              <span style={{ color: "#b9910c", fontSize: "16px" }}>
                Semester Title:
              </span>{" "}
              {semester.title}
            </h4>
            <strong style={{ marginBottom: "15px", display: "block" }}>
              Number of Courses selected for this semester -{" "}
              <span style={{ color: "green" }}>
                {api_data.selected_semester_course}
              </span>
            </strong>
            <strong style={{ marginBottom: "15px", display: "block" }}>
              Total units selected for this semester -{" "}
              <span style={{ color: "green" }}>
                {api_data.selected_given_credit_for_semester}
              </span>
            </strong>
            {errors?.course_ids && (
              <>
                <span style={{ color: "red" }}>{errors?.course_ids[0]}</span>
              </>
            )}
            {sections.length > 0 ? (
              <>
                {sections.map((item) => (
                  <>
                    <SemesterSectionBox
                      registration_status = {registration_status}
                      calendar_id={atob(id)}
                      semester_id={atob(sem_id)}
                      student_id={atob(student_id)}
                      course_ids={course_ids}
                      section_id={item.id}
                      set_course_ids={set_course_ids}
                      item={item}
                    />
                  </>
                ))}
              </>
            ) : (
              <></>
            )}
            {sem_reg_id ? (
              <>
                <div className="input-box">
                  {loader ? (
                    <>
                      <Button type="primary">
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: "12px",
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            />
                          }
                        />
                        Save
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button type="primary" onClick={UPDATE_API}>
                        Save
                      </Button>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="input-box">
                  {loader ? (
                    <>
                      <Button type="primary" className="lusisbtn">
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: "12px",
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            />
                          }
                        />
                        Save
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button type="primary" onClick={ADD_API} className="lusisbtn">
                        Save
                      </Button>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SemesterRegistrationStudent;
