// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.basic-info-flex{
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
}
.basic-info-flex div:first-child{
    max-width: 150px;
    border: 1px solid #ccc;
    overflow: hidden;
    height: 150px;
    border-radius: 8px;
    position: relative;
}
.basic-info-flex div:first-child img{
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.basic-info-flex div{
    flex-basis: 500px;
}
:where(.css-dev-only-do-not-override-11xg00t).ant-select-single {
    font-size: 14px;
    height: 40px;
}
.transferred-course{
    display: block;
    padding: 5px;
    margin: 15px 0px;
    border-radius: 5px;
}
.transferred-course.active{
    background-color: #f5f4f4;
}`, "",{"version":3,"sources":["webpack://./src/pages/semester-registration/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,8BAA8B;AAClC;AACA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC;AACA;IACI,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,YAAY;AAChB;AACA;IACI,cAAc;IACd,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".basic-info-flex{\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    justify-content: space-between;\n}\n.basic-info-flex div:first-child{\n    max-width: 150px;\n    border: 1px solid #ccc;\n    overflow: hidden;\n    height: 150px;\n    border-radius: 8px;\n    position: relative;\n}\n.basic-info-flex div:first-child img{\n    width: 100%;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}\n.basic-info-flex div{\n    flex-basis: 500px;\n}\n:where(.css-dev-only-do-not-override-11xg00t).ant-select-single {\n    font-size: 14px;\n    height: 40px;\n}\n.transferred-course{\n    display: block;\n    padding: 5px;\n    margin: 15px 0px;\n    border-radius: 5px;\n}\n.transferred-course.active{\n    background-color: #f5f4f4;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
