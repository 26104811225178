// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.custom-address-column  {
    width: 150px; 
}

.custom-department-column {
    width: 150px; 
    height: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/faculties/style.css"],"names":[],"mappings":";;;AAGA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB","sourcesContent":["\n\n\n.custom-address-column  {\n    width: 150px; \n}\n\n.custom-department-column {\n    width: 150px; \n    height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
