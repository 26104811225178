// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Student_attend_Class_table {
    width: 1250px;
    height: 131px;
}

.Student_attend_Class_left {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 500;
    font-weight: bold;
    text-align: left;
    color: rgba(59, 130, 246, 1);
}

.Staus_left_Head {
    margin-left: 10px;
}
.Lu_sis_ {
    width: 1,091px;
    border-radius: 4px;
    margin: 10px 0;
    background-color: #BDDDFF;
    padding: 1px 30px 20px 20px;
    max-width: 800px;
}

.Icon_Styling_left {
    margin-right: 10px;
    padding: 3px;
    color: white;
    background: rgba(182, 65, 65, 1);
    /* width: 1.2em !important;
    height: 1.5em !important; */

}

.Icon_Styling_right {
    color: white;
    background: rgba(182, 65, 65, 1);
    margin-left: 10px;
    padding: 2px;
    /* width: 1.2em !important;
    height: 1.5em !important; */
}

.ant-table-thead
{
    background-color: #fff !important;
}

`, "",{"version":3,"sources":["webpack://./src/pages/Attendance/attendance.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,4BAA4B;AAChC;;AAEA;IACI,iBAAiB;AACrB;AACA;IACI,cAAc;IACd,kBAAkB;IAClB,cAAc;IACd,yBAAyB;IACzB,2BAA2B;IAC3B,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,gCAAgC;IAChC;+BAC2B;;AAE/B;;AAEA;IACI,YAAY;IACZ,gCAAgC;IAChC,iBAAiB;IACjB,YAAY;IACZ;+BAC2B;AAC/B;;AAEA;;IAEI,iCAAiC;AACrC","sourcesContent":[".Student_attend_Class_table {\n    width: 1250px;\n    height: 131px;\n}\n\n.Student_attend_Class_left {\n    font-family: 'Roboto';\n    font-size: 18px;\n    font-weight: 500;\n    font-weight: bold;\n    text-align: left;\n    color: rgba(59, 130, 246, 1);\n}\n\n.Staus_left_Head {\n    margin-left: 10px;\n}\n.Lu_sis_ {\n    width: 1,091px;\n    border-radius: 4px;\n    margin: 10px 0;\n    background-color: #BDDDFF;\n    padding: 1px 30px 20px 20px;\n    max-width: 800px;\n}\n\n.Icon_Styling_left {\n    margin-right: 10px;\n    padding: 3px;\n    color: white;\n    background: rgba(182, 65, 65, 1);\n    /* width: 1.2em !important;\n    height: 1.5em !important; */\n\n}\n\n.Icon_Styling_right {\n    color: white;\n    background: rgba(182, 65, 65, 1);\n    margin-left: 10px;\n    padding: 2px;\n    /* width: 1.2em !important;\n    height: 1.5em !important; */\n}\n\n.ant-table-thead\n{\n    background-color: #fff !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
