// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Assuming '.ant-input' class is applied to all dropdowns */
.ant-input {
    width: 100%; /* Example width */
    border: 1px solid #d9d9d9; /* Example border color */
    border-radius: 4px; /* Example border radius */
    padding: 8px; 
    box-sizing: none;
    color: #000;
  }
  
  .css-dev-only-do-not-override-djtmh8 .ant-select-arrow {
    color: #1890ff;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/students/style.css"],"names":[],"mappings":"AAAA,4DAA4D;AAC5D;IACI,WAAW,EAAE,kBAAkB;IAC/B,yBAAyB,EAAE,yBAAyB;IACpD,kBAAkB,EAAE,0BAA0B;IAC9C,YAAY;IACZ,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,cAAc;EAChB","sourcesContent":["/* Assuming '.ant-input' class is applied to all dropdowns */\n.ant-input {\n    width: 100%; /* Example width */\n    border: 1px solid #d9d9d9; /* Example border color */\n    border-radius: 4px; /* Example border radius */\n    padding: 8px; \n    box-sizing: none;\n    color: #000;\n  }\n  \n  .css-dev-only-do-not-override-djtmh8 .ant-select-arrow {\n    color: #1890ff;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
