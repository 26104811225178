// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransferredCourse-row{
    display: flex;
    gap: 10px;
    align-items: center;
}
.TransferredCourse-row label{
    font-size: 10px;
    
}
:where(.css-dev-only-do-not-override-11xg00t).ant-picker {
    box-sizing: border-box;
    margin: 0;
    padding: 8px 11px 8px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    position: relative;
    display: inline-flex;
    align-items: center;
    border-radius: 6px;
    transition: border 0.2s,box-shadow 0.2s,background 0.2s;
}`, "",{"version":3,"sources":["webpack://./src/pages/semester-registration/transferred-course/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;AACA;IACI,eAAe;;AAEnB;AACA;IACI,sBAAsB;IACtB,SAAS;IACT,qBAAqB;IACrB,0BAA0B;IAC1B,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,uLAAuL;IACvL,kBAAkB;IAClB,oBAAoB;IACpB,mBAAmB;IACnB,kBAAkB;IAClB,uDAAuD;AAC3D","sourcesContent":[".TransferredCourse-row{\n    display: flex;\n    gap: 10px;\n    align-items: center;\n}\n.TransferredCourse-row label{\n    font-size: 10px;\n    \n}\n:where(.css-dev-only-do-not-override-11xg00t).ant-picker {\n    box-sizing: border-box;\n    margin: 0;\n    padding: 8px 11px 8px;\n    color: rgba(0, 0, 0, 0.88);\n    font-size: 14px;\n    line-height: 1;\n    list-style: none;\n    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';\n    position: relative;\n    display: inline-flex;\n    align-items: center;\n    border-radius: 6px;\n    transition: border 0.2s,box-shadow 0.2s,background 0.2s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
