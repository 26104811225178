/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Checkbox,
  Input,
  Select,
  Popover,
  TimePicker,
  Button,
  notification,
  Table,
} from "antd";
import Search from "antd/es/transfer/search";
import React, { useEffect, useState } from "react";
import {
  COURSES_LIST,
  SEARCH_COURSE,
  SEMESTERS_COURSES_UPDATE,
  SEMESTERS_COURSES_DELETE,
} from "../../../apis/apis";
import { useNavigate } from "react-router-dom";
import {
  SearchOutlined,
  UserOutlined,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

const SectionSchedule = (props) => {
  const { calendar_id, sem_id, item, set_r_loader } = props;

  const [loader, set_loader] = useState(false);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const columns = [
    {
      title: "Room",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Timing",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Faculty",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Reg. students",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Scheduled",
      dataIndex: "address",
      key: "address",
    },
  ];
  return (
    <>
      {loader ? (
        <LoadingOutlined />
      ) : (
        <>
          <Table pagination={false} dataSource={dataSource} columns={columns} />
        </>
      )}
    </>
  );
};

export default SectionSchedule;
