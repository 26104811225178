import { Editor } from '@tinymce/tinymce-react';
import { Button, Input, Spin, Tree, notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import CourseContainer from './CourseContainer';
import SubSectionContainer from './SubSectionContainer';
import { DeleteOutlined, DownOutlined, LoadingOutlined, SmileOutlined, UpOutlined } from '@ant-design/icons';
import SisLoader from '../../../widgets/loader';
import { ADD_SECTION, DELETE_SECTION, SUBSECTIONS_LIST, UPDATE_SECTION } from '../../../apis/apis';

const SectionContainer = (props) => {
    const {
        temporary_index,
        programme_id,
        section_item,
        set_main_section_loader,
        set_add_section_row_button
    } = props;



    const editorRef = useRef(null);
    const [sub_section, set_sub_section] = useState([]);
    const [show_courses_tab, set_show_courses_tab] = useState(true);
    const [show_courses, set_show_courses] = useState(false);
    const [show_sub_section_tab, set_show_sub_section_tab] = useState(true);
    const [show_sub_section, set_show_sub_section] = useState(false);
    const [section_loader, set_section_loader] = useState(false)
    
    const [section_id, set_section_id] = useState();


    const [loader, set_loader] = useState(true);
    const [button_loader, set_button_loader] = useState(false);
    const [errors, set_errors] = useState([]);
    const [show_container, set_show_container] = useState(false);

    // Form State
    const [title, set_title] = useState('');
    const [courses, set_courses] = useState([]);
    const [unit, set_unit] = useState('');
    const [description, set_description] = useState('');


    // Button 

    const [add_section_button, set_add_section_button] = useState(false);


    const add_sub_section = () => {
        set_show_courses_tab(false);
        set_show_sub_section_tab(true);
        set_show_sub_section(true);
    }
    const add_course = () => {
        set_show_courses_tab(false);
        set_show_sub_section_tab(false);
        set_show_sub_section(false);
        set_show_courses(true);
        set_add_section_button(true);
    }




    const LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('programme_id', programme_id);
        FORM_DATA.append('temporary_index', temporary_index);
        const API_RESPONSE = await SUBSECTIONS_LIST(FORM_DATA);
        if (API_RESPONSE?.data.status) {
            set_sub_section(API_RESPONSE?.data.sub_sections);
            set_section_loader(false);
            set_show_sub_section(false);
        } else {
            set_section_loader(false);
        }
    }
    useEffect(() => {
        if (section_item?.description) {
            var courses_s = section_item?.courses?.split(',').map(Number);
            set_description(section_item?.description);
            set_title(section_item?.title);
            if (courses_s?.length > 0) {
                set_show_courses_tab(false);
                set_show_courses(true)
                set_show_sub_section_tab(false);
            } else {
                set_show_courses_tab(false);
                set_show_sub_section_tab(true);

            }
            set_add_section_button(true)
            set_courses(courses_s);
            set_unit(section_item?.units);
            set_loader(false);

        } else {
            set_loader(false);
            set_show_container(true)
            set_add_section_button(false)
        }

        LIST_API();
    }, [section_loader])






    const ADD_API = async () => {
        set_button_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('title', title);
        // FORM_DATA.append('section_id', section_id)
        FORM_DATA.append('description', editorRef.current.getContent());
        FORM_DATA.append('units', unit);
        FORM_DATA.append('courses', courses);
        FORM_DATA.append('temporary_index', temporary_index);
        FORM_DATA.append('programme_id', programme_id);
        FORM_DATA.append('course_status', show_courses ? 1 : 0);
        FORM_DATA.append('sub_section', show_courses ? 0 : 1);
        const API_RESPONSE = await ADD_SECTION(FORM_DATA);
        if (API_RESPONSE?.data.status) {
            // notification.open({
            //     message: 'Success!!',
            //     description: 'Section Successfully added.',
            //     icon: <SmileOutlined style={{ color: 'green' }} />,
            // });

            set_main_section_loader(true);
            set_add_section_row_button(true);
        } else {
            set_errors(API_RESPONSE?.data.errors);
            set_button_loader(false)
        }
    }
    const UPDATE_API = async () => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', section_item.id);
        FORM_DATA.append('title', title);
        FORM_DATA.append('description', editorRef.current.getContent());
        FORM_DATA.append('units', unit);
        FORM_DATA.append('courses', courses);

        FORM_DATA.append('temporary_index', temporary_index);
        FORM_DATA.append('programme_id', programme_id);
        FORM_DATA.append('sub_section', show_courses ? 0 : 1);
        FORM_DATA.append('course_status', show_courses ? 1 : 0);
        const API_RESPONSE = await UPDATE_SECTION(FORM_DATA);
        if (API_RESPONSE?.data.status) {
            // notification.open({
            //     message: 'Success!!',
            //     description: 'Section Successfully updated.',
            //     icon: <SmileOutlined style={{ color: 'green' }} />,
            // });
            set_main_section_loader(true);
        } else {
            set_errors(API_RESPONSE?.data.errors);
            set_button_loader(false)
        }
    }
    const DELETE_API = async () => {
        if (section_item?.id) {
            const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
            const FORM_DATA = new FormData();
            FORM_DATA.append('token', USER_DATA.token);
            FORM_DATA.append('id', section_item?.id);
            const API_RESPONSE = await DELETE_SECTION(FORM_DATA);
            if (API_RESPONSE?.data.status) {
                notification.open({
                    message: 'Success!!',
                    description: 'Section Successfully deleted.',
                    icon: <SmileOutlined style={{ color: 'green' }} />,
                });
                set_add_section_row_button(true);
                set_main_section_loader(true);
            } else {
                set_main_section_loader(true)
                set_add_section_row_button(true);
            }
        } else {
            set_main_section_loader(true);
            set_add_section_row_button(true);
        }

    }
    console.log(programme_id);
    return (
        <>
            {section_loader ? <SisLoader /> : <>
                <div className='sis-section'>
                    <div className='sis-section-head'>
                        <h4> {title ? title : "Add Programme section"}</h4>
                        <div>
                            <b onClick={DELETE_API}><DeleteOutlined /></b>
                            {show_container ? <b onClick={() => set_show_container(false)}><DownOutlined /> </b> : <b onClick={() => set_show_container(true)}> <UpOutlined /> </b>}
                        </div>
                    </div>


                    {show_container && <>
                        <div className='sis-section-container'>

                            {/* Section Title */}
                            <div className='input-box'>
                                <label htmlFor="name">Section Title<i style={{ color: "red" }}>*</i></label>
                                <Input value={title}
                                    placeholder="Title" id='title'
                                    onChange={(e) => set_title(e.target.value)} />
                                {errors?.title && <><span style={{ color: "red" }}>{errors?.title[0]}</span></>}
                            </div>

                            {/* Section ID */}
                            <div className='input-box'>
                                <label htmlFor='sectionId'>Section ID</label>
                                <Input value={section_id}
                                    placeholder='Section ID' id='sectionId'
                                    onChange={(e) => set_section_id(e.target.value)}
                                />
                            </div>

                            {/* section Description */}
                            <div className='input-box'>
                                <label htmlFor="name">Section  Description<i style={{ color: "red" }}>*</i></label>
                                <Editor
                                    apiKey='i6i6aki8vkxt19vlfxol49qa6zukk6lry8hgtzka6agthn0x'
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    initialValue={description}
                                    init={{
                                        height: 200,
                                        menubar: false,
                                        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount imagetools',
                                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image code media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                                {errors?.description && <><span style={{ color: "red" }}>{errors?.description[0]}</span></>}
                            </div>

                            {/* Section Units */}
                            <div className='input-box'>
                                <label htmlFor="Units">Section Units<i style={{ color: "red" }}>*</i></label>
                                <Input value={unit} maxLength={10}
                                    keyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                                    placeholder="Programme Units" id='Units' onChange={(e) => set_unit(e.target.value)} />
                                {errors?.units && <><span style={{ color: "red" }}>{errors?.units[0]}</span></>}
                            </div>

                            <div className='input-box'>

                                {show_courses && <>
                                    <span style={{ color: "orange", cursor: "not-allowed", opacity: ".5", fontWeight: "bold" }} >+ Add Course</span> or <span style={{ color: "orange", cursor: "not-allowed", opacity: ".5", fontWeight: "bold" }}>+ Add Sub Section</span>
                                </>}
                                {sub_section?.length > 0 ? <>
                                    <span style={{ color: "orange", cursor: "not-allowed", opacity: ".5", fontWeight: "bold" }} >+ Add Course</span> or <span style={{ color: "orange", cursor: "pointer", fontWeight: "bold" }} onClick={add_sub_section}>+ Add Sub Section</span>
                                </> : <>

                                    {show_courses_tab && show_sub_section_tab ? <>
                                        <span style={{ color: "orange", cursor: "pointer", fontWeight: "bold" }} onClick={add_course}>+ Add Course</span> or <span style={{ color: "orange", cursor: "pointer", fontWeight: "bold" }} onClick={add_sub_section}>+ Add Sub Section</span>
                                    </> : <>
                                        {show_sub_section_tab ? <>   <span style={{ color: "orange", cursor: "not-allowed", opacity: ".5", fontWeight: "bold" }} >+ Add Course</span> or <span style={{ color: "orange", cursor: "pointer", fontWeight: "bold" }} onClick={add_sub_section}>+ Add Sub Section</span></> : <></>}


                                    </>}
                                </>}


                            </div>


                            {show_courses ? <>
                                <CourseContainer set_courses={set_courses} courses={courses} />
                                {errors?.courses && <><span style={{ color: "red" }}>{errors?.courses[0]}</span></>}

                            </> : <>
                                {sub_section?.length > 0 && <>

                                    {sub_section.map((item, index) => (
                                        <SubSectionContainer temporary_index={temporary_index} programme_id={programme_id} set_show_sub_section_tab={set_show_sub_section_tab} sub_section={sub_section} set_sub_section={set_sub_section} set_section_loader={set_section_loader} item={item} set_add_section_button={set_add_section_button} />
                                    ))}
                                    {errors?.sub_section && <><span style={{ color: "red" }}>{errors?.sub_section[0]}</span></>}
                                </>}

                                {show_sub_section && <>

                                    <SubSectionContainer temporary_index={temporary_index} programme_id={programme_id} set_show_sub_section_tab={set_show_sub_section_tab} sub_section={sub_section} set_sub_section={set_sub_section} set_section_loader={set_section_loader} item={null} set_add_section_button={set_add_section_button} />
                                    {errors?.sub_section && <><span style={{ color: "red" }}>{errors?.sub_section[0]}</span></>}

                                </>}

                            </>}


                            <div className='input-box' style={{ textAlign: "right" }}>
                                {add_section_button ? <>
                                    {section_item?.id ? <>
                                        {button_loader ? <>
                                            <Button type="primary"><Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} /> Save</Button>
                                        </> : <>
                                            <Button type="primary" onClick={UPDATE_API}>Save</Button>
                                        </>}
                                    </> : <>
                                        {button_loader ? <>
                                            <Button type="primary"><Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} /> Save</Button>
                                        </> : <>
                                            <Button type="primary" onClick={ADD_API}>Save</Button>
                                        </>}
                                    </>}
                                </> : <>
                                    {sub_section?.length > 0 && <>
                                        {section_item?.id ? <>
                                            {button_loader ? <>
                                                <Button type="primary"><Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} /> Save</Button>
                                            </> : <>
                                                <Button type="primary" onClick={UPDATE_API}>Save</Button>
                                            </>}
                                        </> : <>
                                            {button_loader ? <>
                                                <Button type="primary"><Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} /> Save</Button>
                                            </> : <>
                                                <Button type="primary" onClick={ADD_API}>Save</Button>
                                            </>}
                                        </>}

                                    </>}

                                </>}

                            </div>
                        </div>
                    </>}
                </div>
            </>}


        </>
    );
};

export default SectionContainer;