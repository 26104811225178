// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-box{
    display: block;
    max-width: 400px;
    margin: 80px auto;
    background-color: #f3f3f3;
    padding: 20px;
    border-radius: 10px;
}
.password-box-head{
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/set_password/set_password.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,yBAAyB;IACzB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".password-box{\n    display: block;\n    max-width: 400px;\n    margin: 80px auto;\n    background-color: #f3f3f3;\n    padding: 20px;\n    border-radius: 10px;\n}\n.password-box-head{\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
