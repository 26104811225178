import Axios from "../config/config";

//export const SIS_STORAGE = 'http://localhost/lu-sis/public/storage';
export const SIS_STORAGE = 'https://lusis-bucket.s3.amazonaws.com/';

//export const SIS_STORAGE = 'https://dev.icore.uk/storage';

export const sisDateFormat = (inputDate) => {
    if (inputDate) {
        const dateParts = inputDate.split("-");

        // Rearrange the date parts to MM-DD-YYYY
        const formattedDate = `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`;

        return formattedDate;
    }

}

export const sisDateWithShortMonth = (inputDate) => {
    if (inputDate) {
        // Convert the date string to a Date object
        const dateObject = new Date(inputDate);

        // Get the day, month, and year from the Date object
        const day = dateObject.getDate();
        const month = dateObject.toLocaleString('default', { month: 'short' });
        const year = dateObject.getFullYear();

        // Format the date in DD MMM YYYY format
        const formattedDate = `${day} ${month} ${year}`;
        return formattedDate;
    }
}

export const LOGIN_API = async (body) => {
    try {
        const response = await Axios.post('/admin-login', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

// Set Password
export const SET_PASSWORD_API = async (body) => {
    try {
        const response = await Axios.post('/reset-password', body);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const SET_USER_PASSWORD_API = async (body) => {
    try {
        const response = await Axios.post('/reset-password-user', body);
        return response;
    } catch (error) {
        console.log(error);
    }
};

// Check API Token
export const CHECK_PASSWORD_TOKEN = async (body) => {
    try {
        const response = await Axios.post('/check-reset-password-token', body);
        return response;
    } catch (error) {
        console.log(error);
    }
}


export const CHECK_TOKEN = async (body) => {
    try {
        const response = await Axios.post('/admin-token-check', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const DB_LIST = async (body) => {
    try {
        const response = await Axios.post('/role-tables', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


// ROLES

export const ROLE_LIST = async (body) => {
    try {
        const response = await Axios.post('/role-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_ROLE = async (body) => {
    try {
        const response = await Axios.post('/role-add', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const EDIT_ROLE = async (body) => {
    try {
        const response = await Axios.post('/view-role', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_ROLE = async (body) => {
    try {
        const response = await Axios.post('/role-edit', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_ROLE = async (body) => {
    try {
        const response = await Axios.post('/role-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_ROLE = async (body) => {
    try {
        const response = await Axios.post('/role-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

// USERS

export const USERS_LIST = async (body) => {
    try {
        const response = await Axios.post('/users-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_USER = async (body) => {
    try {
        const response = await Axios.post('/user-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const EDIT_USER = async (body) => {
    try {
        const response = await Axios.post('/user-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_USER = async (body) => {
    try {
        const response = await Axios.post('/user-edit', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_USER = async (body) => {
    try {
        const response = await Axios.post('/user-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_USER = async (body) => {
    try {
        const response = await Axios.post('/user-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

// USERS

export const STUDENTS_LIST = async (body) => {
    try {
        const response = await Axios.post('/students-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_STUDENT = async (body) => {
    try {
        const response = await Axios.post('/student-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const EDIT_STUDENT = async (body) => {
    try {
        const response = await Axios.post('/student-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_STUDENT = async (body) => {
    try {
        const response = await Axios.post('/student-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_STUDENT = async (body) => {
    try {
        const response = await Axios.post('/enrollment-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_STUDENT = async (body) => {
    try {
        const response = await Axios.post('/enrollment-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const EMERGENCY_CONTACT = async (body) => {
    try {
        const response = await Axios.post('/student-emergency-contacts-add', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const EMERGENCY_CONTACT_VIEW = async (body) => {
    try {
        const response = await Axios.post('/student-emergency-contacts-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const APPLICATION_DOCUMENT = async (body) => {
    try {
        const response = await Axios.post('/student-documents-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const APPLICATION_DOCUMENT_VIEW = async (body) => {
    try {
        const response = await Axios.post('/student-documents-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};




// categories

export const CATEGORIES_LIST = async (body) => {
    try {
        const response = await Axios.post('/categories-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_CATEGORY = async (body) => {
    try {
        const response = await Axios.post('/categories-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const EDIT_CATEGORY = async (body) => {
    try {
        const response = await Axios.post('/categories-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_CATEGORY = async (body) => {
    try {
        const response = await Axios.post('/categories-edit', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_CATEGORY = async (body) => {
    try {
        const response = await Axios.post('/categories-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_CATEGORY = async (body) => {
    try {
        const response = await Axios.post('/categories-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


// COURSES

export const COURSES_LIST = async (body) => {
    try {
        const response = await Axios.post('/courses-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_COURSE = async (body) => {
    try {
        const response = await Axios.post('/course-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const EDIT_COURSE = async (body) => {
    try {
        const response = await Axios.post('/course-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_COURSE = async (body) => {
    try {
        const response = await Axios.post('/course-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_COURSE = async (body) => {
    try {
        const response = await Axios.post('/course-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_COURSE = async (body) => {
    try {
        const response = await Axios.post('/course-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const SEARCH_COURSE = async (body) => {
    try {
        const response = await Axios.post('/courses-search', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
// Rooms

// Add Room

export const ADD_ROOM = async (body) => {
    try {
        const response = await Axios.post('/classroom-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

// Rooms List 

export const CLASSROOMS_LIST = async (body) => {
    try {
        const response = await Axios.post('/classrooms-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

// Room Status


export const STATUS_ROOM = async (body) => {
    try {
        const response = await Axios.post('/classroom-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


// Room Delete 

export const DELETE_ROOM = async (body) => {
    try {
        const response = await Axios.post('/classroom-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

// Room Edit 
export const UPDATE_ROOM = async (body) => {
    try {
        const response = await Axios.post('/classroom-update', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const EDIT_ROOM = async (body) => {
    try {
        const response = await Axios.post('/classroom-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

// Enrollments

// Add enrollment

export const ADD_ENROLLMENT = async (body) => {
    try {
        const response = await Axios.post('/student-enrollment-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const ENROLLMENTS_UPDATE = async (body) => {
    try {
        const response = await Axios.post('/enrollment-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const ENROLLMENTS_UPDATE_POPUP = async (body) => {
    try {
        const response = await Axios.post('/enrollment-update-2', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
}
// New Enrollment 
export const NEW_ENROLLMENT = async (body) => {
    try {
        const response = await Axios.post('/enrollment-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}
export const VIEW_ENROLLMENT = async (body) => {
    try {
        const response = await Axios.post('/enrollment-view', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

// Enrollment List 

export const ENROLLMENTS_LIST = async (body) => {
    try {
        const response = await Axios.post('/student-enrollments-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

//program enrollment list view 
export const PROGRAM_ENROLLMENTS_LIST_VIEW = async (body) => {
    try {
        const response = await Axios.post('/student-view', body);
        return response;
    } catch (error) {
        console.log(error)
    }
}

// ** Enrollment List New
export const STUDENTS_ENROLLMENTS_LIST = async (body) => {
    try {
        const response = await Axios.post('/enrollments-list', body);
        return response;
    } catch (error) {
        console.log(error);
    }
};
export const STUDENTS_PROGRAM_REGISTRATION_LIST = async (body) => {
    try {
        const response = await Axios.post('/student-program-register-listing', body);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const STUDENTS_ENROLLMENTS_VIEW = async (body) => {
    try {
        const response = await Axios.post('/enrollment-view', body);
        return response;
    } catch (error) {
        console.log(error)
    }
}


// Enrollment Status


export const STATUS_ENROLLMENT = async (body) => {
    try {
        const response = await Axios.post('/student-enrollment-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


// Enrollment Delete 

export const DELETE_ENROLLMENT = async (body) => {
    try {
        const response = await Axios.post('/student-enrollment-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

// Update Edit Enrollment 

export const EDIT_ENROLLMENT = async (body) => {
    try {
        const response = await Axios.post('/enrollment-programme-update', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


// View Enrollment 



export const ADD_STUDENT_PROGRAM = async (body) => {
    try {
        const response = await Axios.post('/student_add', body);
        return response
    } catch (error) {
        console.log(error);
    }
}


// Program List 

export const PROGRAMS_LIST = async (body) => {
    try {
        const response = await Axios.post('/programmes-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const STUDENT_VIEW_API = async (body) => {
    try {
        const response = await Axios.post('/student-view', body);
        return response;
    } catch (error) {
        console.log(error)
    }
}

export const ADD_PROGRAM = async (body) => {
    try {
        const response = await Axios.post('/programme-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const EDIT_PROGRAM = async (body) => {
    try {
        const response = await Axios.post('/programme-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_PROGRAM = async (body) => {
    try {
        const response = await Axios.post('/programme-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_PROGRAM = async (body) => {
    try {
        const response = await Axios.post('/programme-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_PROGRAM = async (body) => {
    try {
        const response = await Axios.post('/programme-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};





// SUBSECTIONS

export const SUBSECTIONS_LIST = async (body) => {
    try {
        const response = await Axios.post('/sub-sections-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_SUBSECTION = async (body) => {
    try {
        const response = await Axios.post('/sub-section-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const EDIT_SUBSECTION = async (body) => {
    try {
        const response = await Axios.post('/sub-section-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_SUBSECTION = async (body) => {
    try {
        const response = await Axios.post('/sub-section-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_SUBSECTION = async (body) => {
    try {
        const response = await Axios.post('/sub-section-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_SUBSECTION = async (body) => {
    try {
        const response = await Axios.post('/sub-section-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};






// SUBSECTIONS

export const SECTIONS_LIST = async (body) => {
    try {
        const response = await Axios.post('/sections-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_SECTION = async (body) => {
    try {
        const response = await Axios.post('/section-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const EDIT_SECTION = async (body) => {
    try {
        const response = await Axios.post('/section-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_SECTION = async (body) => {
    try {
        const response = await Axios.post('/section-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_SECTION2 = async (body) => {
    try {
        const response = await Axios.post('/section-update2', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_SECTION3 = async (body) => {
    try {
        const response = await Axios.post('/section-update3', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_SECTION = async (body) => {
    try {
        const response = await Axios.post('/section-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_SECTION = async (body) => {
    try {
        const response = await Axios.post('/section-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};




// Calendar

export const CALENDARS_LIST = async (body) => {
    try {
        const response = await Axios.post('/calendars-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_CALENDAR = async (body) => {
    try {
        const response = await Axios.post('/calendar-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const EDIT_CALENDAR = async (body) => {
    try {
        const response = await Axios.post('/calendar-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_CALENDAR = async (body) => {
    try {
        const response = await Axios.post('/calendar-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_CALENDAR = async (body) => {
    try {
        const response = await Axios.post('/calendar-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_CALENDAR = async (body) => {
    try {
        const response = await Axios.post('/calendar-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

// faculties 

export const FACULTIES_LIST = async (body) => {
    try {
        const response = await Axios.post('/faculties-list', body);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const FACULTIES_LIST_WITHOUT_PAGINATION = async (body) => {
    try {
        const response = await Axios.post('/faculties-list-without-pagination', body);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const ADD_FACULTIES = async (body) => {
    try {
        const response = await Axios.post('/faculty-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;

    } catch (error) {
        console.error(error);
    }
};

export const DELETE_FACULTY = async (body) => {
    try {
        const response = await Axios.post('/faculty-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const VIEW_FACULTY = async (body) => {
    try {
        const response = await Axios.post('/faculty-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const STATUS_FACULTY = async (body) => {
    try {
        const response = await Axios.post('/faculty-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const UPDATE_FACULTY = async (body) => {
    try {
        const response = await Axios.post('/faculty-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};




// Calendar

export const SEMESTERS_LIST = async (body) => {
    try {
        const response = await Axios.post('/semesters-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ALL_SEMESTERS_LIST = async (body) => {
    try {
        const response = await Axios.post('/semester-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const SEMESTERS_COURSES_LIST = async (body) => {
    try {
        const response = await Axios.post('/calendars-schedules-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const SEMESTERS_COURSES_UPDATE = async (body) => {
    try {
        const response = await Axios.post('/calendar-schedules-update', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const SEMESTERS_COURSES_DELETE = async (body) => {
    try {
        const response = await Axios.post('/calendar-schedules-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_SEMESTER = async (body) => {
    try {
        const response = await Axios.post('/semester-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const EDIT_SEMESTER = async (body) => {
    try {
        const response = await Axios.post('/semester-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_SEMESTER = async (body) => {
    try {
        const response = await Axios.post('/semester-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_SEMESTER = async (body) => {
    try {
        const response = await Axios.post('/semester-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_SEMESTER = async (body) => {
    try {
        const response = await Axios.post('/semester-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};



// SEMESTER_REGISTRATION_VIEW

export const SEMESTER_REGISTRATION_VIEW = async (body) => {
    try {
        const response = await Axios.post('/semester-registration-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const SEMESTER_REGISTRATION_ADD = async (body) => {
    try {
        const response = await Axios.post('/semester-registration-add', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const SEMESTER_REGISTRATION_STUDENT_VIEW = async (body) => {
    try {
        const response = await Axios.post('/semester-registration-student-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const SEMESTER_REGISTRATION_UPDATE = async (body) => {
    try {
        const response = await Axios.post('/semester-registration-update', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


export const ADD_CLASS_SECTION = async (body) => {
    try {
        const response = await Axios.post('/calendar-schedules-add-section', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const REMOVE_CLASS_SECTION = async (body) => {
    try {
        const response = await Axios.post('/calendar-schedules-row-remove', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


export const TRANSFERRED_COURSE_ADD = async (body) => {
    try {
        const response = await Axios.post('/transferred-course-add', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


export const TRANSFERRED_COURSE_UPDATE = async (body) => {
    try {
        const response = await Axios.post('/transferred-course-update', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const TRANSFERRED_COURSE_LIST = async (body) => {
    try {
        const response = await Axios.post('/transferred-course-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const TRANSFERRED_COURSE_DELETE = async (body) => {
    try {
        const response = await Axios.post('/transferred-course-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const SUBSECTIONS_DATA = async (body) => {
    try {
        const response = await Axios.post('/semester-registration-student-view-sub-section', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


export const SEMESTER_COURSE_REGISTRATION_ADD = async (body) => {
    try {
        const response = await Axios.post('/semester-course-registration-add', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const SEMESTER_COURSE_REGISTRATION_UPDATE = async (body) => {
    try {
        const response = await Axios.post('/semester-course-registration-update', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const SEMESTER_COURSE_REGISTRATION_REMOVE = async (body) => {
    try {
        const response = await Axios.post('/semester-course-registration-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ACADEMIC_SEMESTER = async (body) => {
    try {
        const response = await Axios.post('/academic-semester', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const ADMIN_STUDENTS_LISTING = async (body) => {
    try {
        const response = await Axios.post('/admin-students-listing', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STUDENT_PROFILE = async (body) => {
    try {
        const response = await Axios.post('/student-profile', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STUDENT_DOCUMENTS_LIST = async (body) => {
    try {
        const response = await Axios.post('/student-documents-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STUDENTS_DOCUMENTS_ADD = async (body) => {
    try {
        const response = await Axios.post('/students-documents-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const ACADEMIC = async (body) => {
    try {
        const response = await Axios.post('/academic', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const STUDENT_GRADE = async (body) => {
    try {
        const response = await Axios.post('/student-grades', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const SEMESTER_WITH_REGISTERED_COURSE = async (body) => {
    try {
        const response = await Axios.post('/semester-with-courses', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STUDENTS_LISTING_BY_SEMESTER_AND_COURSE = async (body) => {
    try {
        const response = await Axios.post('/students-listing-by-semester-and-course', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_COMMENT = async (body) => {
    try {
        const response = await Axios.post('/student-grades-comment-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const LIST_COMMENT = async (body) => {
    try {
        const response = await Axios.post('/student-grades-comments-list', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const UPDATE_GRADE = async (body) => {
    try {
        const response = await Axios.post('/student-grades-update', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

//billing module

export const BILLING_STUDENT_LIST = async (body) => {
    try {
        const response = await Axios.post('/billing', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const STUDENT_BILLING_PERIOD_DETAIL = async (body) => {
    try {
        const response = await Axios.post('/billing-period', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const ADD_BILLING_DETAIL = async (body) => {
    try {
        const response = await Axios.post('/billing-fees-add', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const ADD_PAYMENT_PLAN_API = async (body) => {
    try {
        const response = await Axios.post('/billing-plan-add', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


export const ADD_PAYMENT_INSTALLMENT_API = async (body) => {
    try {
        const response = await Axios.post('/billing-plan-dates-add', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


export const ADD_FINAL_PUBLISH_BILL_API = async (body) => {
    try {
        const response = await Axios.post('/student-final-bill', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const PAYMENT_PLAN_SELECT_OR_CHANGE_API = async (body) => {
    try {
        const response = await Axios.post('/payment-plan-select-or-change', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

// student service API

export const LIST_STUDENT_HEALTH_INSURANCE = async (body) => {
    try {
        const response = await Axios.post('/student-list-health-insurance', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


export const STUDENT_SERVICE_ADD_UPDATE = async (body) => {

    try {
        const response = await Axios.post('/add-update-student-semester-health-insurance', body);
        console.log('eshant' + response)
        return response;
    } catch (error) {
        console.error(error);
    }
};


export const VIEW_PAYMENT_PLAN_TABLE_API = async (body) => {
    try {
        const response = await Axios.post('/billing-plan-dates-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const FACULTY_MODULES_CALENDER_LIST_API = async (body) => {
    try {
        const response = await Axios.post('/faculty-list-by-datetime', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


export const ADD_ATTENDANCE_CATEGORY = async (body) => {
    try {
        const response = await Axios.post('/add-attendance-category', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


export const VIEW_ATTENDANCE_CATEGORY = async (body) => {
    try {
        const response = await Axios.post('/view-attendance-category', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_ATTENDANCE_CATEGORY = async (body) => {
    try {
        const response = await Axios.post('/edit-attendance-category', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const LIST_ATTENDANCE_CATEGORY = async (body) => {
    try {
        const response = await Axios.post('/list-attendance-category', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const REASONS_LIST = async (body) => {
    try {
        const response = await Axios.post('/reasons-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


export const ADD_REASON = async (body) => {
    try {
        const response = await Axios.post('/add-reason', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const VIEW_REASON = async (body) => {
    try {
        const response = await Axios.post('/reason-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const REASONS_UPDATE = async (body) => {
    try {
        const response = await Axios.post('/reasons-update', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const REASONS_DELETE = async (body) => {
    try {
        const response = await Axios.post('/reasons-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const ATTENDANCE_LIST = async (body) => {
    try {
        const response = await Axios.post('/attendance-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ATTENDANCE_VIEW = async (body) => {
    try {
        const response = await Axios.post('/attendance-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_UPDATE_ATTENDANCE = async (body) => {
    try {
        const response = await Axios.post('/add-update-attendance', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const ATTENDANCE_CANCEL = async (body) => {
    try {
        const response = await Axios.post('/attendance-cancel', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const ADD_UPDATE_GRADE_PLAN = async (body) => {
    try {
        const response = await Axios.post('/add-update-grade-plan', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const VIEW_GRADE_PLAN = async (body) => {
    try {
        const response = await Axios.post('/view-grade-plan', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const STUDENT_ACADEMIC = async (body) => {

    try {
        const response = await Axios.post('/student-academic', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


export const GRADES = async (body) => {
    try {
        const response = await Axios.post('/student-grades', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const STUDENT_BY_SEMESTER_ATTENDANCE = async (body) => {
    try {
        const response = await Axios.post('/student-by-semester-attendance', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const STUDENT_BY_SEMESTER_ATTENDANCE_REPORT = async (body) => {
    try {
        const response = await Axios.post('/student-by-semester-attendance-report', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const ACADEMIC_REPORT_GRAPH = async (body) => {
    try {
        const response = await Axios.post('/academic-report-graph', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};



export const DEMOGRAPHICS_REPORT_GRAPH = async (body) => {
    try {
        const response = await Axios.post('/demography-report-graph', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const ATTENDANCE_REPORT_GRAPH = async (body) => {
    try {
        const response = await Axios.post('/attendance-report-graph', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const DEMOGRAPHICS_REPORT_STATISTICS = async (body) => {
    try {
        const response = await Axios.post('/demography-report-statistics', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const ATTENDANCE_REPORT_STATISTICS = async (body) => {
    try {
        const response = await Axios.post('/attendance-report-statistics', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};


export const ALL_ATTENDANCE_REPORT_GRAPH = async (body) => {
    try {
        const response = await Axios.post('/all-attendance-report-graph', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const ACADEMIC_REPORT_STATISTICS = async (body) => {
    try {
        const response = await Axios.post('/academic-report-statistics', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};


export const ALL_ACADEMIC_REPORT_GRAPH = async (body) => {
    try {
        const response = await Axios.post('/all-academic-report-graph', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};


// Online Application

export const STUDENTS_SELF_ENROLLMENTS_LIST = async (body) => {
    try {
        const response = await Axios.post('/self-enrollments-list', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const SAVE_APPLICATION_CHECKLIST_STATUS = async (body) => {
    try {
        const response = await Axios.post('/application-checklist-status', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const SAVE_SCHOOL_DECISION_STATUS = async (body) => {
    try {
        const response = await Axios.post('/school-decision-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const PERSONAL_INFORMATION_SAVE = async (body) => {
    try {
        const response = await Axios.post('/add-student-info-contact-details-submit-draft', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GET_PERSONAL_INFORMATION = async (body) => {
    try {
        const response = await Axios.post('/get-student-info-contact-details', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}


export const DEMOGRAPHIC_INFORMATION_SAVE = async (body) => {
    try {
        const response = await Axios.post('/student-demographic-information', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GET_DEMOGRAPHIC_INFORMATION = async (body) => {
    try {
        const response = await Axios.post('/get-student-application-documents-submit-draft', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const FINANCE_DECLARATION_SAVE = async (body) => {
    try {
        const response = await Axios.post('/add-student-finance-declaration-submit-draft', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GET_FINANCE_DECLARATION = async (body) => {
    try {
        const response = await Axios.post('/get-student-finance-declaration-submit-draft', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GET_AGREEMENT = async (body) => {
    try {
        const response = await Axios.post('/get-student-signature', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const AGREEMENT_SAVE = async (body) => {
    try {
        const response = await Axios.post('/add-student-signature', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GET_PAYMENT_STATUS = async (body) => {
    try {
        const response = await Axios.post('/payment-api', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const STUDENT_REQUEST_LIST = async (body) => {
    try {
        const response = await Axios.post('/students-request', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const STUDENT_REPEAT_REQUEST_DETAIL = async (body) => {
    try {
        const response = await Axios.post('/student-request-detail', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const STUDENT_REQUEST_DETAIL_SUBMIT = async (body) => {
    try {
        const response = await Axios.post('/student-request-detail-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const STUDENT_ADD_DROP_DETAIL = async (body) => {
    try {
        const response = await Axios.post('/add-drop-view', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const STUDENT_ADD_COURSE_DETAIL = async (body) => {
    try {
        const response = await Axios.post('/students-add-course-view', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const STUDENT_ADD_COURSE_REQUEST_STATUS = async (body) => {
    try {
        const response = await Axios.post('/students-request-status', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const STUDENT_WITHDRAW_DETAIL = async (body) => {
    try {
        const response = await Axios.post('/withdraw-view', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const ADVANCE_ACADEMIC_PERFORMANCE_REPORT = async (body) => {
    try {
        const response = await Axios.post('/advance-academic-performance-reports', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const ADVANCE_ENROLLMENT_AND_REGISTRATION_REPORT = async (body) => {
    try {
        const response = await Axios.post('/advance-enrollment-registration-reports', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const ADVANCE_ENROLLMENT_APPLICATION_TRACKING_REPORT = async (body) => {
    try {
        const response = await Axios.post('/advance-enrollment-application-tracking-reports', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const ADVANCE_STUDENT_DEMOGRAPHICS_REPORT = async (body) => {
    try {
        const response = await Axios.post('/advance-demographic-reports', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const ADVANCE_FINANCIAL_REPORT = async (body) => {
    try {
        const response = await Axios.post('/advance-financial-reports', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const ADVANCE_ACADEMIC_PERFORMANCE_REPORT_CSV_DOWNLOAD = async (body) => {
    try {
        const response = await Axios.post('/csv-advance-academic-performance-reports', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GET_SYSTEM_SETTING = async (body) => {
    try {
        const response = await Axios.post('/admin-system-setting', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const UPDATE_SYSTEM_SETTING = async (body) => {
    try {
        const response = await Axios.post('/system-setting-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const ADD_STUDENT_NEW_PROGRAM = async (body) => {
    try {
        const response = await Axios.post('/new-program-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const UPDATE_STUDENT_PROGRAM = async (body) => {
    try {
        const response = await Axios.post('/update-program-status', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const STUDENT_ACADEMIC_HISTORY_LIST = async (body) => {
    try {
        const response = await Axios.post('/student-historical-programs', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}
export const STUDENT_ACADEMIC_HISTORY_DETAILS = async (body) => {
    try {
        const response = await Axios.post('/student-single-historic-program-detail', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const SELECT_SEARCH_COURSE_LIST = async (body) => {
    try {
        const response = await Axios.post('/courses-list-select', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}