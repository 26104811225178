import { DeleteOutlined, DownOutlined, FormOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import SectionContainer from './SectionContainer';
import { SECTIONS_LIST } from '../../../apis/apis';
import SisLoader from '../../../widgets/loader';
import { useNavigate, useParams } from 'react-router-dom';

const SisSections = (props) => {
    const { id } = useParams();
    const [programme_id, set_programme_id] = useState('');
    const navigate = new useNavigate();
    const [sis_sections, set_sis_sections] = useState([]);
    const [main_section_loader, set_main_section_loader] = useState(false);
    const [show_sis_sections, set_show_sis_sections] = useState(false);
    const [add_section_row_button, set_add_section_row_button] = useState(true);
    const [page_loader, set_page_loader] = useState(true)



    const add_section_row = () => {
        set_show_sis_sections(true);
        set_add_section_row_button(false)
    }
    const LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('programme_id', atob(id));
        const API_RESPONSE = await SECTIONS_LIST(FORM_DATA);
        if (API_RESPONSE?.data.status) {
            set_sis_sections(API_RESPONSE?.data.sections);
            set_show_sis_sections(false);
            set_main_section_loader(false);
            set_page_loader(false);
        } else {
            set_main_section_loader(false);
        }
    }
    useEffect(() => {
        id && LIST_API();

    }, [main_section_loader])

    return (
        <>
            {page_loader ? <SisLoader /> : <>

                <div className='row'>
                    <div className='col-12'>
                        <div className='theme-content-head'>
                            <div className='theme-content-left-head'>
                                {/* <p>Units Requirement met 123</p> */}
                            </div>
                            <div className='theme-content-right-head'>
                                {add_section_row_button ? <><Button type='primary' onClick={add_section_row}>Add Section</Button></> : <><Button type='primary' disabled>Add Section</Button></>}
                            </div>
                        </div>
                        {main_section_loader ? <SisLoader /> : <>

                            {sis_sections.length > 0 && <>
                                {sis_sections.map((item, index) => (
                                    <SectionContainer temporary_index={index} programme_id={atob(id)} set_main_section_loader={set_main_section_loader} section_item={item} set_add_section_row_button={set_add_section_row_button} />
                                ))}

                            </>}
                            {show_sis_sections && <>
                                <SectionContainer temporary_index={sis_sections.length} programme_id={atob(id)} set_main_section_loader={set_main_section_loader} section_item={null} set_add_section_row_button={set_add_section_row_button} />

                            </>}
                            {sis_sections.length > 0 && <>
                                <Button type="primary" onClick={() => navigate('/programmes-list')}>Submit</Button>

                            </>}
                        </>}


                    </div>
                </div>
            </>}
        </>
    );
};

export default SisSections;