import React, { useEffect, useState } from 'react';
import { Table, Input, Avatar, Button, Pagination } from 'antd';
import { UpOutlined, DownOutlined, EyeFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ADMIN_STUDENTS_LISTING, SIS_STORAGE } from '../../../apis/apis';
import SisLoader from '../../../widgets/loader';
import maleAvatar from "../../../images/user.png";



const Studentstally = () => {
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);

  const [loader, set_loader] = useState(true);
  const [errors, set_errors] = useState([]);
  // Form State
  const [table_list, set_table_list] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [last_page, set_last_page] = useState(1);
  const [total, set_total] = useState("");
  const [per_page, set_per_page] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [activeTab, setActiveTab] = useState("Active"); 

  

 // Search
 const handleSearch = (e) => {
  const query = e.target.value;
  setSearchQuery(query);
};


  const columns = [
    {
      title: "ID",
      dataIndex: "enrollment_id",
      key: "id",
      sorter: (a, b) => a.id - b.id,

    },
    {
      title: 'Name',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={

              record.enrollment_image
              ? `${SIS_STORAGE}/enrollments/${record.enrollment_image}`
               : maleAvatar


            }
            frameborder="0"
            style={{ width: "30px", height: "30px", borderRadius: "30px", marginRight: "10px" }}
          ></img>
          {record.enrollment_first_name} {record.enrollment_middle_name !== 'null' && record.enrollment_middle_name && record.enrollment_middle_name} {record.enrollment_last_name}
        </div>
      ),

    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            Programme
          </div>
          {sortColumn === 'programme' && sortOrder === 'asc' && <UpOutlined />}
          {sortColumn === 'programme' && sortOrder === 'desc' && <DownOutlined />}
        </div>
      ),
      dataIndex: 'programme_title',
      key: 'programme_title',
    },
    {
      title: 'Programme status',
      dataIndex: 'programme_status',
      key: 'programme_status',
    },
    {
      title: 'Student type',
      dataIndex: 'student_type',
      key: 'student_type',
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            Action
          </div>
        </div>
      ),
      dataIndex: 'dot',
      key: 'dot',
      render: (text, record) => (
        <Button type="primary" size="small" onClick={() => navigate('/student-detail/' + btoa(record.enrollment_id))} style={{ marginLeft: "5px" }}><EyeFilled /></Button>
        
      ),
    },
  ];


  // DB list
  const LIST_API = async (UserSearchPage,UserQuery, activeTab) => {
    set_loader(true)
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    FORM_DATA.append("page", UserSearchPage ? UserSearchPage : currentPage);
    FORM_DATA.append("search", UserQuery);
    FORM_DATA.append("student_type",activeTab);

    const API_RESPONSE = await ADMIN_STUDENTS_LISTING(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_table_list(API_RESPONSE.data.enrollments_students?.data);
      setFilteredData(API_RESPONSE.data.enrollments_students?.data);
      setCurrentPage(API_RESPONSE?.data?.enrollments_students?.current_page);
      set_last_page(API_RESPONSE?.data?.enrollments_students?.last_page);
      set_total(API_RESPONSE?.data?.enrollments_students?.total);
      set_per_page(API_RESPONSE?.data?.enrollments_students?.per_page);
      set_loader(false);
    } else {
      set_loader(false);
    }
  }

    useEffect(() => {      
      const timer = setTimeout(() => {
        setDebouncedQuery(searchQuery);
        LIST_API("",searchQuery, activeTab)
      }, 600);
  
      return () => clearTimeout(timer); 
    }, [searchQuery]);
  
  
    const pagination_changes  = (UserSearchPage) => {

      setCurrentPage(UserSearchPage);
      LIST_API(UserSearchPage,debouncedQuery, activeTab)

   };

    const handleTabClick = (tab) => {

        setActiveTab(tab); 
        LIST_API(currentPage,searchQuery, tab); 
    
    };
  


  // useEffect(() => {
  //   LIST_API();
  // }, [loader]);


 
  return (
    <>
     {loader ? <SisLoader /> : 
    <div>
      <div className="theme-content-head">
        <div className="theme-content-left-head">
          <h3>Students</h3>
        </div>
      </div>

      <div className="theme-content-head">

        <div className="sis-tab-menu">

          <span
            className={activeTab === "Active" ? "active" : ""}
            onClick={() => handleTabClick("Active")}
          >
            Active Students
          </span>

          <span
            className={activeTab === "Alumni" ? "active" : ""}
            onClick={() => handleTabClick("Alumni")}
          >
            Alumni Students
          </span>

          <span
            className={activeTab === "Other" ? "active" : ""}
            onClick={() => handleTabClick("Other")}
          >
            Other Students
          </span>    

       </div>

       <div className="input-box" style={{ marginTop: "120px" }}>
          <Input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => handleSearch(e)}
          />
          {errors?.code && (
            <span style={{ color: "red" }}>{errors?.code[0]}</span>
          )}
        </div>


      </div>

     <Table columns={columns} dataSource={filteredData} pagination={false} />
     <div style={{float:"right", marginTop:"20px"}}><Pagination onChange={pagination_changes} defaultCurrent={parseInt(currentPage)} total={parseInt(total)} pageSize={parseInt(per_page)} /></div>
      
    </div>
    }
    </>
  );
};
export default Studentstally;




