import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Spin, notification } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import { useNavigate } from 'react-router-dom';
import { CloseOutlined, CloudUploadOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import { ADD_COURSE, ADD_PROGRAM, EDIT_PROGRAM, SIS_STORAGE, UPDATE_PROGRAM } from '../../../apis/apis';
const SisProgramme = (props) => {
    const { set_programme_id, set_show_section, set_show_basic, id}  = props;
    const editorRef = useRef(null); 
    const navigate = useNavigate();
    const [loader, set_loader] = useState(false);
    const [errors, set_errors] = useState([]);

    // Form State
    const [title, set_title] = useState('');
    const [unit, set_unit] = useState('');
    const [image, set_image] = useState('');
    const [description, set_description] = useState('');
    const [url_image, set_url_image] = useState('');
    const VIEW_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('id', id);
        FORM_DATA.append('token', USER_DATA.token);
        const EDIT_ROLE_API_RESPONSE = await EDIT_PROGRAM(FORM_DATA);
        if (EDIT_ROLE_API_RESPONSE?.data?.status) {
            set_title(EDIT_ROLE_API_RESPONSE?.data?.programme?.title);
            set_unit(EDIT_ROLE_API_RESPONSE?.data?.programme?.unit);
            set_description(EDIT_ROLE_API_RESPONSE?.data?.programme?.description)
            set_image(EDIT_ROLE_API_RESPONSE?.data?.programme?.image)
        } else {
            set_errors(EDIT_ROLE_API_RESPONSE?.data?.errors);
        }
    }

    const handleImageChange = (e) => {
        const files = e.target.files;
        if ((files[0].type === 'image/jpeg' || files[0].type === 'image/jpg' || files[0].type === 'image/png') && files[0].size < 2000001) {
            set_image(files[0]);
        } else {
            notification.open({
                message: 'Not Allowed',
                description: 'Please check image File format must be .jpg or .png and Max. file size 2 Mb',
                icon: <SmileOutlined style={{ color: 'red' }} />,
            });
        }
    }
    const handleRemoveImage = (e) => {
        set_image('');
    }

    const ADD_API = async () => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('title', title);
        FORM_DATA.append('description', editorRef.current.getContent());
        FORM_DATA.append('image', image);
        FORM_DATA.append('unit', unit);
        const API_RESPONSE = await ADD_PROGRAM(FORM_DATA);
        if (API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Program Basic Information Successfully added.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            set_programme_id(API_RESPONSE.data.id);
            set_show_basic(false)
            set_show_section(true)
        } else {
            set_errors(API_RESPONSE.data.errors);
            set_loader(false)
        }
    }
    const UPDATE_API = async () => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', id);
        FORM_DATA.append('title', title);
        FORM_DATA.append('description', editorRef.current.getContent());
        FORM_DATA.append('image', image);
        FORM_DATA.append('unit', unit);
        const API_RESPONSE = await UPDATE_PROGRAM(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            notification.open({
                message: 'Success!!',
                description: 'Program Basic Information Successfully Updated.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            set_programme_id(atob(id));
            set_show_basic(false)
            set_show_section(true)
        } else {
            set_errors(API_RESPONSE.data.errors);
            set_loader(false)
        }
    }
    
    useEffect(() => {
       id && VIEW_API();
        
    }, []);
    return (

      <>
        {id ? <>
            <div className='row'>
            <div className='col-12'>

                <div className='input-box'>
                    <label htmlFor="name">Program Title<i style={{ color: "red" }}>*</i></label>
                    <Input value={title} placeholder="Title" id='title' onChange={(e) => set_title(e.target.value)} />
                    {errors?.title && <><span style={{ color: "red" }}>{errors?.title[0]}</span></>}
                </div>
                <div className='input-box'>
                    <label htmlFor="name">Program Description<i style={{ color: "red" }}>*</i></label>
                    <Editor
                        apiKey='i6i6aki8vkxt19vlfxol49qa6zukk6lry8hgtzka6agthn0x'
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={description}
                        init={{
                            height: 500,
                            menubar: false,
                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount imagetools',
                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image code media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                    />
                    {errors?.description && <><span style={{ color: "red" }}>{errors?.description[0]}</span></>}
                </div>
                <div className='input-box'>
                    <label htmlFor="Units">Program Units<i style={{ color: "red" }}>*</i></label>
                    <Input  value={unit} maxLength={10} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} placeholder="Program Units" id='Units' onChange={(e) => set_unit(e.target.value)} />
                    {errors?.unit && <><span style={{ color: "red" }}>{errors?.unit[0]}</span></>}
                </div>
                <div className='input-box' style={{ position: "relative" }}>
                            <label htmlFor="address">Program Photo</label><br></br>
                            <label className='upload-box'> <input type="file" id="upload-images" accept="image/*" style={{ height: "0px", overflow: "hidden" }} onChange={handleImageChange} /> <CloudUploadOutlined /> Upload Image</label>
                            {url_image ?
                                <>
                                    <div className="image-box" style={{ backgroundImage: "url(" + URL.createObjectURL(url_image) + ")" }}></div>
                                    <button className='image-remove' onClick={() => handleRemoveImage()}><CloseOutlined /></button>
                                </>
                                : <>

                                    {image &&
                                        <>
                                            <div className="image-box" style={{ backgroundImage: "url(" + SIS_STORAGE + "/programmes/" + image + ")" }}></div>
                                          
                                        </>

                                    }
                                </>}

                            {errors?.image && <><span style={{ color: "red" }}>{errors?.image[0]}</span></>}

                        </div>

                <div className='input-box' style={{ marginTop: "20px" }}>
                    {loader ? <>
                        <Button type="primary"><Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} /> Save & Next</Button>
                    </> : <>
                        <Button type="primary" onClick={UPDATE_API}>Update & Next</Button>
                    </>}
                </div>
            </div>
        </div>
        
        </> : <>
        <div className='row'>
            <div className='col-12'>

                <div className='input-box'>
                    <label htmlFor="name">Program Title<i style={{ color: "red" }}>*</i></label>
                    <Input placeholder="Title" id='title' onChange={(e) => set_title(e.target.value)} />
                    {errors?.title && <><span style={{ color: "red" }}>{errors?.title[0]}</span></>}
                </div>
                <div className='input-box'>
                    <label htmlFor="name">Program Description<i style={{ color: "red" }}>*</i></label>
                    <Editor
                        apiKey='i6i6aki8vkxt19vlfxol49qa6zukk6lry8hgtzka6agthn0x'
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue=""
                        init={{
                            height: 500,
                            menubar: false,
                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount imagetools',
                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image code media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                    />
                    {errors?.description && <><span style={{ color: "red" }}>{errors?.description[0]}</span></>}
                </div>
                <div className='input-box'>
                    <label htmlFor="Units">Program Units<i style={{ color: "red" }}>*</i></label>
                    <Input maxLength={10} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} placeholder="Program Units" id='Units' onChange={(e) => set_unit(e.target.value)} />
                    {errors?.unit && <><span style={{ color: "red" }}>{errors?.unit[0]}</span></>}
                </div>
                <div className='input-box' style={{ position: "relative" }}>
                    <label htmlFor="address">Program Photo</label><br></br>
                    <label className='upload-box'> <input type="file" id="upload-images" accept="image/*" style={{ height: "0px", overflow: "hidden" }} onChange={handleImageChange} /> <CloudUploadOutlined /> Upload Image</label>
                    {image &&
                        <>
                            <div className="image-box" style={{ backgroundImage: "url(" + URL.createObjectURL(image) + ")" }}></div>
                            <button className='image-remove' onClick={() => handleRemoveImage()}><CloseOutlined /></button>
                        </>
                    }
                    {errors?.image && <><span style={{ color: "red" }}>{errors?.image[0]}</span></>}

                </div>
                <div className='input-box' style={{ marginTop: "20px" }}>
                    {loader ? <>
                        <Button type="primary"><Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} /> Save & Next</Button>
                    </> : <>
                        <Button type="primary" onClick={ADD_API}>Save & Next</Button>
                    </>}
                </div>
            </div>
        </div>
        </>}
      </>

    );
};

export default SisProgramme;