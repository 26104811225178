// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-row{
    display: flex;
    gap: 10px;
    align-items: center;
}
.search-row label{
    font-weight: 500;
}
.course_link{
    cursor: pointer;
    font-weight: bold;
}
.course_link:hover{
    color: rgb(0, 128, 201);
}
.grading-course-head-row{
    display: flex;
    gap: 30px;
}
.grading-course-head-row h3{
    display: inline-block;
    margin-right: 1.875rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/gradess/grading.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,SAAS;AACb;AACA;IACI,qBAAqB;IACrB,sBAAsB;AAC1B","sourcesContent":[".search-row{\n    display: flex;\n    gap: 10px;\n    align-items: center;\n}\n.search-row label{\n    font-weight: 500;\n}\n.course_link{\n    cursor: pointer;\n    font-weight: bold;\n}\n.course_link:hover{\n    color: rgb(0, 128, 201);\n}\n.grading-course-head-row{\n    display: flex;\n    gap: 30px;\n}\n.grading-course-head-row h3{\n    display: inline-block;\n    margin-right: 1.875rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
