/* eslint-disable no-unused-vars */
import { Editor } from '@tinymce/tinymce-react';
import { Button, Input, Spin, notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import CourseContainer from './CourseContainer';
import { DeleteOutlined, DownOutlined, FormOutlined, LoadingOutlined, SmileOutlined, UpOutlined } from '@ant-design/icons';
import { ADD_SUBSECTION, DELETE_SUBSECTION, UPDATE_SUBSECTION } from '../../../apis/apis';
import SisLoader from '../../../widgets/loader';

const SubSectionContainer = (props) => {
    const {
        temporary_index,
        programme_id,
        set_show_sub_section_tab,
        sub_section_id,
        set_sub_section,
        sub_section,
        set_section_loader,
        set_add_section_button,
        item,
    } = props;

    const editorRef = useRef(null);
    const [loader, set_loader] = useState(true);
    const [button_loader, set_button_loader] = useState(false);
    const [errors, set_errors] = useState([]);
    const [show_container, set_show_container] = useState(false);
    // Form State
    const [title, set_title] = useState('');
    const [courses, set_courses] = useState([]);
    const [unit, set_unit] = useState('');
    const [description, set_description] = useState('');

    useEffect(() => {
        if (item?.description) {
            var courses_s = item?.courses?.split(',').map(Number);
            set_description(item?.description);
            set_title(item?.title);
            set_courses(courses_s);
            set_unit(item?.units);
            set_loader(false);
        } else {
            set_loader(false);
            set_show_container(true)
        }
        set_add_section_button(false)
    }, [])
    const ADD_API = async () => {
        set_button_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('title', title);
        FORM_DATA.append('description', editorRef.current.getContent());
        FORM_DATA.append('units', unit);
        FORM_DATA.append('courses', courses);
        FORM_DATA.append('temporary_index', temporary_index);
        FORM_DATA.append('programme_id', programme_id);
        const API_RESPONSE = await ADD_SUBSECTION(FORM_DATA);
        if (API_RESPONSE?.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Sub Section Successfully added.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            set_add_section_button(true);
            set_section_loader(true);
        } else {
            set_errors(API_RESPONSE?.data.errors);
            set_button_loader(false)
        }
    }
    const UPDATE_API = async () => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', item.id);
        FORM_DATA.append('title', title);
        FORM_DATA.append('description', editorRef.current.getContent());
        FORM_DATA.append('units', unit);
        FORM_DATA.append('courses', courses);
        FORM_DATA.append('temporary_index', temporary_index);
        FORM_DATA.append('programme_id', programme_id);
        const API_RESPONSE = await UPDATE_SUBSECTION(FORM_DATA);
        if (API_RESPONSE?.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Sub Section Successfully updated.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            set_add_section_button(true);
            set_section_loader(true);
        } else {
            set_errors(API_RESPONSE?.data.errors);
            set_button_loader(false)
        }
    }
    const DELETE_API = async () => {
        set_loader(true);
        if (item?.id) {
            const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
            const FORM_DATA = new FormData();
            FORM_DATA.append('token', USER_DATA.token);
            FORM_DATA.append('id', item?.id);
            const API_RESPONSE = await DELETE_SUBSECTION(FORM_DATA);
            if (API_RESPONSE?.data.status) {
                notification.open({
                    message: 'Success!!',
                    description: 'Sub section Successfully deleted.',
                    icon: <SmileOutlined style={{ color: 'green' }} />,
                });
                set_section_loader(true);
                
            } else {
                set_loader(false)
            }
        } else {
            set_section_loader(true);
        }

    }
    console.log(sub_section)
    return (
        <>
            {loader ? <SisLoader /> : <>
                <div className='sis-section' style={{ backgroundColor: "#e2e2e2" }}>
                    <div className='sis-section-head'>
                        <h4> {title ? title : "Add New Sub section"} </h4>
                        <div>
                            <b onClick={DELETE_API}><DeleteOutlined /></b>
                            {show_container ? <b onClick={() => set_show_container(false)}><DownOutlined /> </b> : <b onClick={() => set_show_container(true)}> <UpOutlined /> </b>}
                        </div>
                    </div>
                    {show_container && <>
                        <div className='sis-section-container'>
                            <div className='input-box'>
                                <label htmlFor="name">Sub Section Title<i style={{ color: "red" }}>*</i></label>
                                <Input value={title} placeholder="Title" id='title' onChange={(e) => set_title(e.target.value)} />
                                {errors?.title && <><span style={{ color: "red" }}>{errors?.title[0]}</span></>}
                            </div>
                            <div className='input-box'>
                                <label htmlFor="name">Sub Section  Description<i style={{ color: "red" }}>*</i></label>
                                <Editor
                                    apiKey='i6i6aki8vkxt19vlfxol49qa6zukk6lry8hgtzka6agthn0x'
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    initialValue={description}
                                    init={{
                                        height: 200,
                                        menubar: false,
                                        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount imagetools',
                                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image code media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                                {errors?.description && <><span style={{ color: "red" }}>{errors?.description[0]}</span></>}
                            </div>
                            <div className='input-box'>
                                <label htmlFor="Units">Sub Section Units<i style={{ color: "red" }}>*</i></label>
                                <Input value={unit} maxLength={10} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} placeholder="Programme Units" id='Units' onChange={(e) => set_unit(e.target.value)} />
                                {errors?.units && <><span style={{ color: "red" }}>{errors?.units[0]}</span></>}
                            </div>
                            <CourseContainer set_courses={set_courses} courses={courses} />
                            {errors?.courses && <><span style={{ color: "red" }}>{errors?.courses[0]}</span></>}
                            <div className='input-box' style={{ textAlign: "right" }}>
                                {item?.id ? <>
                                    {button_loader ? <>
                                        <Button type="primary"><Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} /> Save</Button>
                                    </> : <>
                                        <Button type="primary" onClick={UPDATE_API}>Save</Button>
                                    </>}
                                </> : <>
                                    {button_loader ? <>
                                        <Button type="primary"><Spin indicator={<LoadingOutlined style={{ fontSize: '12px', color: "#fff", marginRight: "5px" }} />} /> Save</Button>
                                    </> : <>
                                        <Button type="primary" onClick={ADD_API}>Save</Button>
                                    </>}
                                </>}
                            </div>
                        </div>
                    </>}

                </div>
            </>}
        </>


    );
};

export default SubSectionContainer;