import React, { useEffect, useState } from "react";
import "./style.css";
import { Button, DatePicker, Input, Select } from "antd";
import { COURSES_LIST } from "../../../apis/apis";
import { DeleteOutlined } from "@ant-design/icons";
export default function TransferredCourseAdd() {
  const dateFormat = 'MM-DD-YYYY';
  const [courses, set_courses] = useState([]);
  const [table_list, set_table_list] = useState([]);
  const [table_list_2, set_table_list_2] = useState([]);
  const [loader, set_loader] = useState(false);

  const LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await COURSES_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      var filteredData = API_RESPONSE?.data?.courses.filter(
        (item) => item.soft_delete === 0 && item.status === 1
      );
      set_table_list(API_RESPONSE?.data?.courses);
      set_table_list_2(filteredData);
    } else {
      set_loader(false);
    }
  };

  useEffect(() => {
    LIST_API();
  }, []);
  return (
    <div className="TransferredCourse-row">
      <div className="input-box" style={{ flexBasis:"160px" }}>
        <label>LU Course</label>
        <Select
          placeholder="Select Courses"
        //   style={{ width:"160px" }}
          allowClear
          filterOption={(inputValue, option) => {
            return !option.label
              .toLowerCase()
              .indexOf(inputValue.toLowerCase());
          }}
          onChange={(value) => set_courses(value)}
          options={table_list_2.map((item) => ({
            value: item.id,
            label: item.title + "(" + item.code + ")",
          }))}
        ></Select>
      </div>
      <div className="input-box">
        <label>Credit</label>
        <Input style={{ width: "100%" }} />
      </div>
      <div className="input-box">
        <label>Transferred Course</label>
        <Input style={{ width: "100%" }} />
      </div>
      <div className="input-box">
        <label>Transferred institution</label>
        <Input style={{ width: "100%" }} />
      </div>
      <div className="input-box">
        <label>Begin Date</label>
        <DatePicker format={dateFormat} />
      </div>
      <div className="input-box">
        <label>End Date</label>
        <DatePicker format={dateFormat} />
      </div>
      <div className="input-box">
        <label>Credit attempted</label>
        <Select style={{ width:"100%" }}>
            <Select.Option>1</Select.Option>
            <Select.Option>2</Select.Option>
            <Select.Option>3</Select.Option>
        </Select>
      </div>
      <div className="input-box">
        <label>Credit Earn</label>
        <Select style={{ width:"100%" }}>
            <Select.Option>1</Select.Option>
            <Select.Option>2</Select.Option>
            <Select.Option>3</Select.Option>
        </Select>
      </div>
      <div className="input-box">
        <label htmlFor="" style={{color:"#f5f4f4"}}>Action</label>
        <DeleteOutlined style={{color:"red"}} />
      </div>
    </div>
  );
}
