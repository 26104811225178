// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Basic Information */
.basic-information{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: top;
    flex-wrap: wrap;
}

/* Program Registration */
.program-registration{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.program-registration .input-box{
    margin-left: 10px;
    margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/enrollments/style.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;IAChB,eAAe;AACnB;;AAEA,yBAAyB;AACzB;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":["/* Basic Information */\n.basic-information{\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: top;\n    flex-wrap: wrap;\n}\n\n/* Program Registration */\n.program-registration{\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    flex-wrap: wrap;\n}\n\n.program-registration .input-box{\n    margin-left: 10px;\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
